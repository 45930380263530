.hero-image-background {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 350px);
    background-image:   linear-gradient(to bottom, transparent 0%, #1c1917 100%),url('../../../assets/images/dark-town-min.webp');
    background-position: bottom;
    background-size: cover;
    position: relative;
}

.hero-image-background::after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #1c1917;
    width: 100%;
    bottom: 0;
}