/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #111827;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #eee;
}