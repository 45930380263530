.hero-image-background-smartbuild {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 350px);
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.35) 80%, #ffffff 100%),url('../../../assets/images/header_background03-min.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
}

.hero-image-background-smartbuild::after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #fff;
    width: 100%;
    bottom: 0;
}