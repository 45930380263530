.videoTag {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    object-fit: cover;
}

.videoTag--static {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: url('../../../assets/images/Office-min.png');
    background-position: center;
    background-size: cover;
}

.hidden-text {
    position: relative;
    margin-bottom: 0px;
    opacity: 1;
    transition: all 0.3s ease-out;
    display: none;
}

.card:hover .hidden-text {
    margin-bottom: 0;
    opacity: 1;
    transition: all 0.3s ease-out;
}